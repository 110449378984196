
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.ProductionProcessConfigurationVM()
    }) model: OM.ProductionProcessConfigurationVM;
    
    @Prop({
        default: () => []
    }) productionPhases: OM.TextIdentifier[];

    localCompanyList: OM.TextIdentifier[] = [];
    localModel: OM.ProductionProcessConfigurationVM = new OM.ProductionProcessConfigurationVM();
    selectedCompany: string = "";

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
    }

    getCompanyName(identifier: string){
        return this.localCompanyList.find( x => x.identifier == identifier).text;
    }

    get canSave(){
        return this.localModel.name && this.localModel.productionPhase.identifier && this.localModel.emissionFactor;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
