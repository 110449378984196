
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, ProductionPhaseConfigurationClient, ProductionProcessClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ProductionProcess extends Vue {

    items: OM.ProductionProcessConfigurationVM[] = [];
    productionPhases: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            ProductionProcessClient.getAllConfigurations(),
            ProductionPhaseConfigurationClient.getAllConfigurations()
        ])
        .then(xs => {
            this.items = xs[0];
            this.productionPhases = xs[1].map( x => {
                return {
                    identifier: x.identifier,
                    text: x.productionPhase
                }
            });
            this.loaded = true;
        })
    }

    editConfiguration(item: OM.ProductionProcessConfigurationVM) {
        if(!item){
            item = new OM.ProductionProcessConfigurationVM();
            item.isMandatory = true;
        }

        this.$opModal.show(EditModal, {
            model: item,
            productionPhases: this.productionPhases,
            callback: (updatedModel: OM.ProductionProcessConfigurationVM) => {
                ProductionProcessClient.editConfiguration(updatedModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }

}
